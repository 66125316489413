<template>
  <div class="text-black flex">
    <Sidebar />
    <div class="font-semibold flex-1 h-screen">
      <Navbar title="Tickets" />
      <div id="tickets-content" class="p-7">
        Select any of the following subscriptions to either renew your Texperia subscription or upgrade to a better plan.
        <div
          class="w-full mt-4"
          v-if="tickets"
        >
          <div class="flex flex-row">
            <div class="w-1/3" v-for="(ticket, index) in tickets" :key="ticket.slug">
              <div 
                :class="{
                  'border-r border-dashed border-gray-800': index != tickets.length - 1
                }"
                class="grid px-10">
                <img :src="require('@/assets/images/tickets/'+ticket.slug+'-top.png')" />
                <div @click.prevent="changePlan(ticket.plan_id)">
                  <img :src="require('@/assets/images/tickets/'+ticket.slug+'-bottom-renew.png')" class="bottomLayer cursor-pointer" v-if="ticket.current" />
                  <img :src="require('@/assets/images/tickets/'+ticket.slug+'-bottom-upgrade.png')" class="bottomLayer cursor-pointer" v-else />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmationModal
          @closeModal="modal.show = false"
          @change="submitChangeRequest()"
          :modal="modal"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/Common/Sidebar/Standard";
import Navbar from "@/components/Common/Navbar/Standard";
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import ConfirmationModal from "@/components/Modals/ConfirmationModal.vue";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  name: "Tickets",
  components: { Sidebar, Navbar, ConfirmationModal },
  data() {
    return {
      dataFetched: false,
      tickets: null,
      planId: null,
      modal: {
        show: false,
        heading: "Switch Plan",
        question: "Are you sure you want to switch the plan?",
        action: "change",
        showLottie: true,
        lottiePath: "lotties/warning.json",
      },
    };
  },
  mounted() {
    const vue = this;
    ApiService.get(apiResource.tickets.get)
      .then(({ data }) => {
        vue.dataFetched = true;
        vue.tickets = data.data.details;
      })
      .catch(() => {});
  },
  methods: {
    changePlan(planId) {
      this.planId = planId;
      this.modal.show = true;
    },
    submitChangeRequest() {
      ApiService.post(apiResource.tickets.change, {
        plan_id: this.planId,
      })
        .then(({ data }) => {
          toast.success(data.msg, { timeout: false });
        })
        .catch((error) => {
          toast.warning(error.response.data.msg, { timeout: false });
        });
    },
  },
};
</script>

<style scoped lang="postcss">
.bottomLayer:hover {
  transition-duration: 500ms;
  transform: rotate(0.025turn);
  transform-origin: left top;
}
</style>
